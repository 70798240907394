@import "../../assets/styles/scss/variables";

.bloc-entertainment {
  display: flex;
  flex-direction: column;
  border-radius: $rounded-m;
  padding: 1.94rem 1.61rem;
  box-shadow: $shadow;
  display: flex;

  .img-container {
    display: flex;
    align-items: flex-end;
    overflow: hidden;
    border-radius: $rounded-m;
    max-height: 24rem;
    height: 24rem;
    min-height: 24rem;

    img {
      max-height: 24rem;
      height: 24rem;
      min-height: 24rem;
    }
  }

  .entertainment-infos {
    padding-top: 1.5rem;
    padding-bottom: 1.33rem;
    display: flex;
    flex-direction: column;
    gap: .39rem;
  }

  .entertainment-content {
    padding-bottom: 3.11rem;
  }

  .entertainement-price {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    padding-bottom: 2.39rem;

    .tag,
    h4 {
      color: $color-red;
    }
  }

  .tag.creator {
    color: #191010;
    border-radius: .55556rem;
    border: solid 1px #191010;
    padding: .33rem 1rem;
    margin: 1rem 0 .5rem;
    font-size: 1rem;
    font-weight: 600;
    line-height: 1.38889rem;
  }

  @media(max-width: 991px) {
    .img-container {
      max-height: 14rem;
      height: 14rem;
      min-height: 14rem;

      img {
        max-height: 14rem;
        height: 14rem;
        min-height: 14rem;
      }
    }
  }

  @media(max-width: 768px) {
    flex-direction: column;
    gap: 1.33rem;

    .entertainment-content {
      padding-bottom: 1.33rem;
    }
  }
}