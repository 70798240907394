@import "../../assets/styles/scss/variables";

ul.socials {
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  gap: .9rem;
  
  a {
    color: $color-primary !important;
  }
}