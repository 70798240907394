@import "../../assets/styles/scss/variables";

.bloc-blog {
  display: flex;
  flex-direction: column;
  gap: 1.72rem;

  .img-container {
    display: flex;
    width: 100%;
    max-height: 22rem;
    height: 22rem;
    min-height: 22rem;
    overflow: hidden;
    border-radius: $rounded-m;
    position: relative;

    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      max-height: 22rem;
      height: 22rem;
      min-height: 22rem;
      scale: 1.02;
      transition: all ease 0.7s;
    }

    .go-to {
      width: 1.8rem;
      height: 1.8rem;
      position: absolute;
      top: .7rem;
      right: .7rem;
      border-radius: $rounded-xl;
      background-color: $color-white;
      display: flex;
      align-items: center;
      justify-content: center;
      transition: all ease 0.4s;
      overflow: hidden;

      i {
        transition: all ease 0.4s;
      }
    }
  }

  h3 {
    font-size: clamp(1.2rem, 2.2vw, 1.8rem);
    transition: all ease 0.2s;
  }

  &:hover {
    .img-container {
      img {
        scale: 1;
        transition: all ease 0.4s;
      }
    }
    
    h3 {
      color: $color-red;
      transition: all ease 0.2s;
    }

    .go-to {
      overflow: visible;

      i {
        transform: rotate(45deg);
      }
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .blog-content {
      display: flex;
      flex-direction: column;
      gap: .8rem;
    }
  }

  .tag-container {
    display: flex;
    gap: 1rem;
    align-items: flex-start;
    padding-bottom: 1rem;

    .tag {
      color: $color-primary;
      border-radius: $rounded-xs;
      border: solid 1px $color-primary;
      padding: 0.33rem 1rem;
      font-weight: 600;
    }
  }

  .blog-content {
    display: flex;
    flex-direction: column;
    gap: 0.56rem;
  }

  .blog-infos {
    padding-top: 1rem;
    display: flex;
    justify-content: flex-start;
    gap: 2.61rem;
  }

  @media(max-width: 1440px) {
    .img-container {
      max-height: 20rem;
      height: 20rem;
      min-height: 20rem;

      img {
        max-height: 20rem;
        height: 20rem;
        min-height: 20rem;
      }
    }
  }

  @media(max-width: 991px) {
    .img-container {
      max-height: 16.5rem;
      height: 16.5rem;
      min-height: 16.5rem;

      img {
        max-height: 16.5rem;
        height: 16.5rem;
        min-height: 16.5rem;
      }
    }
  }

  @media(max-width: 640px) {
    .img-container {
      max-height: 14rem;
      height: 14rem;
      min-height: 14rem;

      img {
        max-height: 14rem;
        height: 14rem;
        min-height: 14rem;
      }
    }
  }
}