@import "../../assets/styles/scss/variables";

.ts-wrapper {
  width: 100%;

  &.single.input-active {
    .ts-control {
      background: transparent;
    }
  }

  .ts-control,
  .ts-wrapper .ts-dropdown {
    border-radius: $rounded-m;
    background-color: $color-white;
    font-weight: 500;
    font-size: $regular;
  }

  .ts-control {
    min-width: 190px;
    background-color: transparent;
    border-radius: $rounded-xl;
    border: solid $color-red 1px;
    justify-content: flex-start;
    padding: .8rem 1.5rem;
    padding-right: 3.2rem !important;
    line-height: normal;
    font-weight: 500;
    font-size: $regular;
    outline: none;
    transition: all 0.3s ease;

    @media (max-width: 768px) {
      padding: .7rem 1.2rem;
      font-size: .9rem;
    }

    .item {
      text-transform: none;
      font-weight: 500;
      color: $color-red;
      transition: all 0.3s ease;
    }

    &:after {
      background: transparent;
      display: block;
      color: $color-primary;
      width: 1rem;
      height: 1rem;
      min-width: 1rem;
      min-height: 1rem;
      content: url('../../assets/images/chevron-down-regular.svg');
      transform-origin: center;
      transition: all 0.3s ease;
    }
  }

  &:hover {
    .ts-control {
      background-color: $color-red;
      transition: all 0.3s ease;


      .item {
        color: $color-white;
        transition: all 0.3s ease;
      }

      &:after {
        color: $color-white;
        transition: all 0.3s ease;
        filter: grayscale(1) invert(1) contrast(5);
        transform: translateY(-50%) rotate(180deg);
      }
    }
  }

  .ts-dropdown {
    overflow: hidden;
    width: 100%;
    min-width: fit-content;

    .option {
      padding: .7rem;
      font-size: 1.11rem;
      line-height: 1.4rem;

      &.active {
        background-color: #ffc2c175;
      }

      &[aria-disabled="true"] {
        background-color: #f0f0f0;
        pointer-events: none;
        opacity: 0.6;
      }
    }

    .selected.active {
      color: $color-primary;
    }

    border-radius: 0 0 $rounded-m $rounded-m;
  }

  .ts-dropdown-content::-webkit-scrollbar {
    width: 12px;
    height: 8px;
    background-color: $color-white;
    border-radius: $rounded-m !important;
  }

  .ts-dropdown-content::-webkit-scrollbar-thumb {
    background: #abb8c3;
    border-radius: $rounded-m;

  }

  .ts-wrapper.single.input-active .ts-control {
    background-color: $color-white;
  }

  &.dropdown-active {
    .ts-control {
      outline: none;
      border-radius: $rounded-m $rounded-m 0 0;
      border: #eee solid 1px;
      background-color: $color-white;
      color: $color-red;

      &:after {
        transform-origin: center;
      }
    }

    &:hover {
      .ts-control {
        background-color: $color-white;
        transition: all 0.3s ease;


        .item {
          color: $color-red;
          transition: all 0.3s ease;
        }

        &:after {
          color: $color-red;
          transition: all 0.3s ease;
          filter: inherit;
        }
      }
    }
  }
}