@import "../../assets/styles/scss/variables";

.bloc-subscription {
  display: flex;
  flex-direction: column;
  padding: 1.38rem 1.63rem;
  box-shadow: $shadow;
  border-radius: $rounded-m;
  position: relative;

  .discount-container {
    display: flex;
    justify-content: flex-end;
    position: absolute;
    top: 1rem;
    right: 1rem;

    .discount {
      font-size: clamp(.9rem, 1.2vw, .9rem);
      line-height: clamp(1rem, 1.8vw, 1.38889rem);
      font-weight: 800;
      color: $color-white;
      background-color: $color-red;
      width: fit-content;
      padding: 0.48rem .78rem;
      border-radius: $rounded-xs;
    }
  }

  .subscription {
    display: flex;
    flex-direction: column;
    gap: 1.83rem;
    height: 100%;
    justify-content: space-between;

    .top,
    .bottom {
      display: flex;
      flex-direction: column;
    }

    h3 {
      font-size: clamp(1.1rem, 1.6vw, 2rem);
      line-height: clamp(1.4rem, 2vw, 2.2rem);
      max-width: 75%;

      &.title {
        min-height: 6.5rem;
        padding-bottom: 2rem;
      }

      @media(max-width: 1240px) {
        font-size: clamp(1.4rem, 2.6vw, 2rem);

        &.title {
          min-height: 3.5rem;
        }
      }

      @media(max-width: 768px) {
        font-size: clamp(1.4rem, 3.5vw, 2rem);

        &.title {
          min-height: 3.5rem;
        }
      }
    }

    .price-container {
      display: flex;
      align-items: flex-end;
      gap: .5rem;

      .price-month {
        font-weight: 600;
      }
    }

    .prices {
      display: flex;
      flex-direction: column;

      .price-item {
        display: flex;
        flex-direction: column;
        padding-bottom: 2rem;

        .short-descr,
        .advantage,
        .discount {
          font-weight: 600;
          margin-bottom: 0;
        }

        .advantage {
          padding-top: .7rem;
          color: $color-gray;
        }

        .short-descr {
          padding-bottom: .7rem;
        }
      }
    }

    .advantages {
      display: flex;
      flex-direction: column;
      gap: .44rem;
      list-style-type: none;
      padding-left: 0;

      .item {
        position: relative;
        width: calc(100% - 1.68rem);
        font-weight: 600;
        left: 1.68rem;
        font-size: clamp(.9rem, 1.2vw, 1rem);
        line-height: clamp(1rem, 1.8vw, 1.6rem);

        &:before {
          top: 0;
          content: "\f00c";
          font-family: 'Font Awesome 6 Pro';
          position: absolute;
          display: block;
          color: $color-red;
          opacity: 1;
          font-style: normal;
          font-weight: 400;
          left: -1.68rem;
          font-size: 1.3rem;
          line-height: 1.38889rem;
        }

        @media(max-width: 768px) {
          &:before {
            font-size: 1rem;
            top: -2px;
          }
        }
      }
    }
  }
}