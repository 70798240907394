//
// Template Styles
// --------------------------------------------------

#main {
    z-index: 15;
}

.filters {
    display: flex;
    flex-direction: row;
    gap: .94rem;

    @media(max-width: 767px) {
        flex-direction: column;
    }
}

#page-letters {
    padding-bottom: 3.33rem;
    padding-top: 3.5rem;

    @media(max-width: 768px) {
        padding-top: 2.33rem;
        padding-bottom: 3.33rem;
    }

    .pagination {
        padding-top: 1.5rem;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        color: $color-pink;

        a {
            color: $color-pink;
            padding: 0 0.61rem;
            font-size: 1rem;
            font-style: normal;
            font-weight: 500;
            line-height: normal;

            &.current,
            &:hover {
                color: $color-red;
            }
        }

        .loader {
            width: 0;
            transition: width 0.3s ease;
            height: 40px;

            path {
                fill: $color-red;
            }

            &.loading {
                width: 40px;
                transition: width 0.3s ease;
            }
        }
    }

    .bloc-letter:last-child {
        border-bottom: none;
    }
}

#page-blogs {
    padding-top: 3rem;
    padding-bottom: 5rem;

    @media(max-width: 768px) {
        padding-top: 2.33rem;
        padding-bottom: 3.33rem;
    }

    .fitlers-container {
        padding-bottom: 2rem;
    }

    #posts-grid {
        .container {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            column-gap: 1.94rem;
            row-gap: 3.94rem;

            @media (max-width: 1440px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media (max-width: 768px) {
                grid-template-columns: repeat(1, 1fr);
            }
        }

        .post-action {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            padding-top: 3.33rem;
        }
    }
}

#page-team {
    padding: 5rem 0 5rem;

    .inner-container {
        display: flex;
        flex-direction: column;
        gap: 5rem;

        .content {
            .headline {
                padding: 1.22rem 0 1.67rem;
            }
        }
    }

    @media(max-width: 991px) {
        padding: 3.22rem 0 9.83rem;
    }
}

#page-books,
#page-games {
    padding-top: 3.33rem;
    padding-bottom: 5.55rem;

    #posts-grid {
        padding-top: 3rem;

        .container {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            gap: 1.11rem;

            @media(max-width: 1640px) {
                grid-template-columns: repeat(3, 1fr);
            }

            @media(max-width: 1240px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media(max-width: 1240px) {
                grid-template-columns: repeat(2, 1fr);
            }

            @media(max-width: 768px) {
                grid-template-columns: repeat(1, 1fr);
            }
        }

        .post-action {
            padding-top: 3.33rem;
        }
    }

    @media(max-width: 767px) {
        padding-top: 2.33rem;
        padding-bottom: 5.55rem;

        #posts-grid {
            padding-top: 2rem;
        }
    }
}

#error-template {
    margin-top: var(--header-height);
    min-height: calc(100svh - calc(var(--header-height) + var(--footer-height)));

    .error-page {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 4rem;

        .left {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            justify-content: center;

            h1 {
                font-size: clamp(8rem, 20vw, 20rem);

                span {
                    font-size: clamp(8rem, 20vw, 20rem);
                    font-weight: 700;
                    color: $color-primary;
                }
            }
        }

        .right {
            display: flex;
            flex-direction: column;
            gap: 2rem;
            align-items: flex-start;
            justify-content: center;
        }

        .error-title {
            display: flex;
            flex-direction: column;
            gap: 2rem;

            span {
                position: relative;
                display: inline-block;
                opacity: 0;
                transform: translateY(-100vh);
                transition: transform 1s ease-out, opacity 1s ease-out;

                &.animate {
                    opacity: 1;
                    transform: translateY(0);
                }
            }
        }

        .error-message,
        .btn-primary {
            opacity: 0;
            transform: translateX(-50px);
            transition: transform 0.5s ease-out, opacity 0.5s ease-out;
        }

        .error-message.animate,
        .btn-primary.animate {
            opacity: 1;
            transform: translateX(0);
        }

        @media (max-width: 1240px) {
            .right {
                .btn-primary {
                    gap: 1.8rem;
                }
            }
        }

        @media (max-width: 991px) {
            display: flex;
            flex-direction: column;
            gap: 4rem;

            .left {
                align-items: flex-start;
            }
        }

        @media (max-width: 480px) {
            .right {
                .btn-primary {
                    justify-content: space-between;
                    width: 100%;
                }
            }
        }
    }

    @media (max-width: 991px) {
        margin-top: 0;
    }
}

#page-travels {
    .travels {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding-top: 3.33rem;
        padding-bottom: 7.5rem;
        gap: 1.111rem;

        .bloc-travel {
            h3 {
                font-size: clamp(1.4rem, 2.4vw, 2rem);
                line-height: clamp(2rem, 3vw, 3rem);
            }

            .exerpt {
                font-size: clamp(.9rem, 1.2vw, 1rem);
            }
        }

        @media(max-width: 768px) {
            grid-template-columns: repeat(1, 1fr);
        }
    }

    .old-travels {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        padding-top: 3.33rem;
        padding-bottom: 3.33rem;
        gap: 1.111rem;

        .bloc-travel {
            .img-container {
                max-height: 18rem;
                height: 18rem;
                min-height: 18rem;

                img {
                    max-height: 18rem;
                    height: 18rem;
                    min-height: 18rem;
                }
            }

            .content {
                h3 {
                    font-size: clamp(1.4rem, 1.5vw, 2rem);
                    line-height: clamp(2rem, 2vw, 3rem);
                }

                .exerpt {
                    font-size: clamp(.9rem, .9vw, 1rem);
                }
            }
        }

        @media(max-width: 1240px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media(max-width: 991px) {
            .bloc-travel {
                .img-container {
                    max-height: 16rem;
                    height: 16rem;
                    min-height: 16rem;

                    img {
                        max-height: 16rem;
                        height: 16rem;
                        min-height: 16rem;
                    }
                }
            }
        }

        @media(max-width: 768px) {
            grid-template-columns: repeat(1, 1fr);
        }

        .bloc-travel {
            .img-container {
                max-height: 14rem;
                height: 14rem;
                min-height: 14rem;

                img {
                    max-height: 14rem;
                    height: 14rem;
                    min-height: 14rem;
                }
            }
        }
    }

    .post-action {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 7.5rem;
    }
}

.travel-contact {
    .content {
        padding-top: 3.33rem;
        padding-bottom: 7.5rem;
        display: flex;
        gap: 2.8rem;
        font-weight: 600;

        .travel-contact-infos,
        .travel-contact-email {
            position: relative;
            padding-left: 3.2rem;

            &:before {
                top: 0;
                font-family: 'Font Awesome 6 Pro';
                position: absolute;
                display: block;
                color: $color-white;
                background-color: $color-red;
                left: 0;
                opacity: 1;
                font-weight: 600;
                font-size: 1.2rem;
                border-radius: $rounded-xl;
                width: 2.5rem;
                height: 2.5rem;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }

        .travel-contact-infos {
            &:before {
                content: "\f095";
            }
        }

        .travel-contact-email {
            &:before {
                content: "\f0e0";
            }
        }

        @media (max-width: 920px) {
            flex-direction: column;
            gap: 1.5rem;
        }
    }
}

#page-contact {
    padding-top: calc(3rem + $header-height);

    .container {
        .grid {
            display: grid;
            grid-template-columns: calc(40% - 0.5rem) calc(60% - 0.5rem);
            gap: 1rem;

            @media (max-width: 767px) {
                grid-template-columns: calc(100%);
            }

            .left {
                display: flex;
                flex-direction: column;
                gap: 2.65rem;

                .content {
                    display: flex;
                    gap: 0.75rem;

                    .location {
                        i {
                            color: $color-red;
                        }
                    }

                    .text-content {
                        display: flex;
                        flex-direction: column;
                        gap: 1rem;
                        font-size: clamp(.9rem, 1.5vw, 1.5rem);
                        line-height: clamp(1.2rem, 2.2vw, 1.9rem);
                    }
                }

                .huge {

                    span:first-child {
                        width: 100%;
                        display: flex;
                    }
                }
            }

            .right {
                @media (max-width: 767px) {
                    padding: 2rem 0 0 0;
                }

                .img-contact {
                    display: flex;
                    height: 100%;
                    border-radius: $rounded-m;
                    overflow: hidden;
                    min-height: 32rem;


                    iframe {
                        width: 100%;
                        height: 100%;
                        object-position: center;
                        object-fit: cover;
                    }
                }
            }
        }

        .redaction {
            padding: 4.5rem 0 5rem 0;

            @media (max-width: 767px) {
                padding: 5rem 0;
            }

            .member-sections {
                display: grid;
                grid-template-columns: repeat(3, 1fr);
                row-gap: 4.45rem;
                column-gap: 2rem;
                padding: 3.3rem 0 0 0;

                @media (max-width: 1199px) {
                    grid-template-columns: repeat(2, 1fr);
                }

                @media (max-width: 767px) {
                    grid-template-columns: repeat(1, 1fr);
                    gap: 1.9rem;
                }

                .members {
                    .function {
                        padding: 0 0 1rem 0;
                    }

                    .member-info {
                        padding: 0 0 1rem 0;
                        display: flex;
                        flex-direction: column;
                        gap: 0.4rem;

                        @media (max-width: 767px) {
                            gap: 0.1rem;
                        }
                    }
                }
            }
        }
    }
}

#page-homepage {

    .huge {
        padding-bottom: 2.61rem;
    }

    .grid {
        padding-bottom: 2.61rem;
    }

    #last-blog {
        padding-bottom: 5rem;

        .grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 1.11rem;

            .bloc-blog {
                gap: 1rem;

                .img-container {
                    max-height: 18rem;
                    height: 18rem;
                    min-height: 18rem;

                    img {
                        max-height: 18rem;
                        height: 18rem;
                        min-height: 18rem;

                    }
                }

                .blog-content {
                    gap: 1rem;
                }

                .blog-infos {
                    flex-direction: column;
                    gap: .33rem;
                }
            }

            @media (max-width: 991px) {
                grid-template-columns: repeat(2, 1fr);
                row-gap: 3rem;
            }

            @media (max-width: 768px) {
                grid-template-columns: repeat(1, 1fr);
            }
        }

        .see-more {
            width: 100%;
            display: flex;
            justify-content: center;
        }
    }

    #subscription {
        padding-bottom: 5rem;

        .grid {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            column-gap: 1.111rem;
            row-gap: 5rem;

            @media (max-width: 1240px) {
                grid-template-columns: repeat(2, 1fr);
                row-gap: 3rem;
            }

            @media (max-width: 768px) {
                grid-template-columns: repeat(1, 1fr);
                row-gap: 1.11rem;
            }
        }
    }

    #old-magazine {
        padding-bottom: 5rem;

        .grid {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 1.11rem;

            .bloc-blog {
                gap: 1rem;

                .img-container {
                    max-height: 18rem;
                    height: 18rem;
                    min-height: 18rem;

                    img {
                        max-height: 18rem;
                        height: 18rem;
                        min-height: 18rem;

                    }
                }

                .blog-content {
                    gap: 1rem;
                }

                .blog-infos {
                    flex-direction: column;
                    gap: .33rem;
                }
            }

            @media (max-width: 991px) {
                grid-template-columns: repeat(2, 1fr);
                row-gap: 3rem;
            }

            @media (max-width: 768px) {
                grid-template-columns: repeat(1, 1fr);
            }
        }

        .see-more {
            width: 100%;
            display: flex;
            justify-content: center;
        }
    }

    #services {
        padding-bottom: 5rem;

        .grid {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            column-gap: 1.111rem;
            row-gap: 5rem;
        }
    }
}

#page-contest {
    .container {
        .intro {
            padding-top: 1.5rem;
            font-weight: 600;
        }

        .section-participation {
            padding: 6.75rem 0;

            .trip-action {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 1.95rem;

                @media (max-width: 1199px) {
                    grid-template-columns: repeat(1, 1fr);
                }

                .content {
                    border-radius: $rounded-m;
                    padding: 1.9rem 4.1rem;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    box-shadow: $shadow;

                    @media (max-width: 768px) {
                        padding: 1.75rem 1.45rem;
                    }

                    .headline {
                        padding: 1.1rem 0 3.2rem 0;
                        margin: 0;
                        font-weight: 600;
                    }

                    a {
                        color: $color-white;

                        &:hover {
                            color: $color-red;
                        }
                    }
                }

                .content:first-of-type {
                    background-color: $color-red;
                    color: $color-white;

                    a {
                        background-color: $color-white;
                        color: $color-text;
                        border: $color-white 1px solid;

                        &:hover {
                            color: $color-red;
                        }
                    }
                }
            }

            @media (max-width: 768px) {
                padding: 3.75rem 0;
            }
        }

        .price {
            padding: 2.05rem 0 6.75rem;

            .container {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                gap: 1rem;


                @media(max-width:991px) {
                    grid-template-columns: repeat(1, 1fr);
                }

                .bloc-price {
                    &:first-child {
                        grid-column: span 2;

                        .img-container {
                            max-height: 30rem;
                            min-height: 30rem;
                            height: 30rem;

                            img {
                                max-height: 30rem;
                                min-height: 30rem;
                                height: 30rem;
                            }
                        }

                        @media(max-width:991px) {
                            grid-column: span 1;

                            .img-container {
                                max-height: 18rem;
                                min-height: 18rem;
                                height: 18rem;

                                img {
                                    max-height: 18rem;
                                    min-height: 18rem;
                                    height: 18rem;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

#page-subscription {
    .intro {
        width: 50%;
        padding-top: 2.72rem;

        @media (max-width: 991px) {
            width: 100%;
        }
    }

    #subscription {
        padding-top: 3rem;
        padding-bottom: 5rem;

        .grid {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            column-gap: 1.111rem;
            row-gap: 5rem;
            padding-bottom: 1.111rem;

            @media (max-width: 1240px) {
                grid-template-columns: repeat(2, 1fr);
                row-gap: 3rem;
            }

            @media (max-width: 768px) {
                grid-template-columns: repeat(1, 1fr);
                row-gap: 1.11rem;
            }
        }

        .address-change {
            display: flex;
            padding: 2.22222rem;
            flex-direction: column;
            align-items: flex-start;
            gap: 3.88889rem;
            box-shadow: $shadow;
            border-radius: $rounded-m;

            h3 {
                padding-bottom: .7rem;
            }

            >div {
                width: 70%;
            }
        }
    }
}

#page-form {
    .intro {
        width: 50%;
        padding-top: 2.72rem;

        @media (max-width: 991px) {
            width: 100%;
        }
    }

    .product-name {
        padding: 0 0 2rem;
    }

    .contact-form {
        padding-top: 2.72rem;
        padding-bottom: 5.55rem;
    }
}

#page-publicity {
    padding-bottom: 5.55rem;

    .publicity-zone {
        .gallery {
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 1.89rem;
            padding-top: 1.89rem;

            .gallery-item {
                padding: 2rem;
                overflow: hidden;
                border-radius: $rounded-xs;
                box-shadow: $shadow;
            }
        }
    }

    .publicity-contact {
        padding: 5rem 0 7.5rem;

        .contact-details {
            padding-top: 2.22rem;
            display: grid;
            grid-template-columns: repeat(2, 1fr);

            .contact-item {
                display: flex;
                flex-direction: column;
                position: relative;
                gap: 2.11rem;

                .info {
                    display: flex;
                    flex-direction: column;
                    position: relative;
                    left: 2.8rem;
                    font-size: clamp(.7rem, 1.5vw, 1rem);
                    font-weight: 600;

                    &:before {
                        font-family: 'Font Awesome 6 Pro';
                        color: $color-red;
                        font-size: .9rem;
                        margin-right: 1rem;
                        left: -3rem;
                        color: white;
                        background-color: $color-red;
                        font-weight: 900;
                        width: 2rem;
                        height: 2rem;
                        position: absolute;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: $rounded-xl;
                    }

                    &.mail {
                        &:before {
                            content: "\f0e0";
                        }
                    }

                    &.phone {
                        &:before {
                            content: "\f879";
                        }
                    }

                    &.address {
                        &:before {
                            content: "\f3c5";
                        }
                    }
                }
            }
        }
    }

    .publicity-prices {
        box-shadow: $shadow;
        border-radius: $rounded-m;
        padding: 2.11rem 4.56rem;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;

        .publicity-infos {
            display: flex;
            flex-direction: column;
            gap: 1.28rem;
        }
    }

    @media(max-width: 1080px) {

        .publicity-zone {
            .gallery {
                grid-template-columns: repeat(2, 1fr);

                .gallery-item {
                    padding: 1rem;
                }
            }
        }

        .publicity-prices {
            flex-direction: column;
            padding: 2.11rem 2.56rem;
            width: 100%;
            align-items: flex-end;
            gap: 1.68rem;

            .publicity-infos {
                width: 100%;
                display: flex;
                flex-direction: column;
                gap: .68rem;
            }
        }
    }

    @media(max-width: 768px) {

        .publicity-zone {
            .gallery {
                gap: 1rem;

                .gallery-item {
                    padding: .56rem;
                }
            }
        }

        .publicity-contact {
            padding: 3rem 0 3.5rem;

            .contact-details {
                grid-template-columns: repeat(1, 1fr);
                gap: 2rem;

                .contact-item {
                    gap: 1rem;
                }
            }
        }

        .publicity-prices {
            padding: 1.11rem;
        }
    }
}

#page-story {
    padding-top: 3rem;
    padding-bottom: 5.55rem;

    @media(max-width: 768px) {
        padding-top: 2.33rem;
        padding-bottom: 3.33rem;
    }

    .history {
        .story-container {
            .story-content {
                display: flex;
                justify-content: space-between;
                width: 100%;
                padding-bottom: 7.67rem;

                &.full {
                    .right {
                        width: 100%;
                    }
                }

                &.mid {
                    .right {
                        width: calc(60% - 2.95rem);
                    }

                    .left {
                        width: calc(40% - 2.95rem);
                        overflow: hidden;
                        border-radius: $rounded-xs;

                        img {
                            width: 100%;
                            object-fit: contain;
                            border-radius: $rounded-xs;
                        }
                    }
                }
            }

            &:nth-child(odd) {
                .story-content {
                    flex-direction: row-reverse;
                }
            }

            &:nth-child(even) {
                .story-content {
                    flex-direction: row;
                }
            }
        }

        .year-title {
            color: rgba(230, 28, 24, 0.50);
            font-size: clamp(4rem, 8vw, 12.74467rem);
            font-weight: 700;
            line-height: clamp(3rem, 8vw, 12.67578rem);
            letter-spacing: clamp(-0.2rem, 12vw, -.8rem);
            padding-bottom: 1.5rem;
        }

        .story-content {
            p {
                margin-bottom: .3rem;
            }
        }
    }

    .team-img {
        display: flex;
        justify-content: center;

        .img-container {
            border-radius: $rounded-xs;

            img {
                border-radius: $rounded-xs;
            }
        }
    }

    @media(max-width:1080px) {
        .history {
            .year-title {
                padding-bottom: 1.33rem;
            }
        }
    }

    @media(max-width:768px) {
        .history {
            .story-container {
                padding-bottom: 5rem;

                .story-content {
                    display: flex;
                    justify-content: space-between;
                    width: 100%;
                    padding-bottom: 1.22rem;

                    &.full {
                        .right {
                            width: 100%;
                        }
                    }

                    &.mid {
                        .right {
                            width: 100%;
                        }

                        .left {
                            width: 100%;

                            img {
                                margin-bottom: 1.22rem;
                            }
                        }
                    }
                }

                &:nth-child(odd) {
                    .story-content {
                        flex-direction: column;
                    }
                }

                &:nth-child(even) {
                    .story-content {
                        flex-direction: column;
                    }
                }
            }

            .year-title {
                padding-bottom: 1.22rem;
            }
        }
    }
}

#page-blog-single {
    padding-top: 1.28rem;

    #post-content {
        padding-bottom: 7.5rem;

        .container {
            .post-content {
                width: 70%;

                @media (max-width: 768px) {
                    width: 100%;
                }

                .post-infos {
                    width: fit-content;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                }

                a {
                    color: $color-red;
                }
            }
        }
    }

    #related-posts {
        .grid {
            padding-top: 2.11rem;
            padding-bottom: 7.5rem;
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            gap: 1.11rem;

            @media(max-width: 991px) {
                grid-template-columns: repeat(1, 1fr);
                gap: 4.11rem;
            }
        }
    }
}

#page-letter-single {
    padding-top: 1.28rem;

    #post-content {
        padding-bottom: 7.5rem;

        .container {
            .post-content {
                width: 70%;

                @media (max-width: 768px) {
                    width: 100%;
                }
            }
        }
    }

    #related-posts {
        .grid {
            padding-top: 2.11rem;
            padding-bottom: 7.5rem;

            .bloc-letter:last-child {
                border-bottom: none;
            }
        }
    }
}

#page-travel-single {
    padding-top: 1.28rem;

    #post-content {

        .container {
            padding-bottom: 2rem;

            .grid {
                display: grid;
                grid-template-columns: repeat(2, 1fr);
                padding: 4rem 0 2.35rem 0;
                gap: 2.61rem;

                .price {
                    display: flex;
                    flex-direction: column;
                    gap: 1.78rem;

                    .price-infos {
                        display: flex;
                        flex-direction: column;
                        gap: 1.11rem;

                        .price-block {
                            display: flex;
                            flex-direction: column;
                            gap: 0.35rem;
                            width: fit-content;

                            .price-item {
                                display: flex;
                                justify-content: space-between;
                                gap: 1rem;

                                p {
                                    font-weight: 600;
                                    margin: 0;
                                }
                            }
                        }
                    }
                }

                .info {
                    display: flex;
                    flex-direction: column;
                    gap: 1.11rem;

                    ul {
                        margin: 0;
                    }
                }

                @media(max-width: 768px) {
                    display: flex;
                    flex-direction: column-reverse;
                }
            }
        }

        .travel-contact {
            padding-bottom: 0;
        }
    }

    #related-posts {
        .grid {
            padding-top: 2.11rem;
            padding-bottom: 7.5rem;
            display: grid;
            grid-template-columns: repeat(3, 1fr);
            gap: 1.11rem;

            @media(max-width: 1240px) {
                grid-template-columns: repeat(1, 1fr);
            }
        }
    }
}

#page-standard {
    padding-bottom: 3.33rem;
    padding-top: 3.5rem;

    @media(max-width: 768px) {
        padding-top: 2.33rem;
        padding-bottom: 3.33rem;
    }

    .post-content {
        max-width: 1080px;
        display: flex;
        flex-direction: column;
        justify-content: center;
    }
}