//
// Base Styles
// --------------------------------------------------

html,
body {
  font-family: $font-base;
  font-size: $regular; //18px
  line-height: clamp(1.5rem, 2vw, 1.6rem);
  font-weight: normal;
  color: $color-text;
  scroll-behavior: smooth;

  @media (max-width:767px) {
    font-size: $small;
  }
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  margin: 0;
}

.unscroll {
  overflow-y: hidden !important;
}

.is-hidden,
.hidden {
  display: none !important;
}

.invisible,
.not-visible {
  visibility: hidden;
}

.xsmall {
  font-size: $xsmall;
  line-height: normal;
  font-weight: normal;
}

.small {
  font-size: $small;
  line-height: normal;
  font-weight: normal;
}

p,
li,
.regular {
  font-size: clamp(.9rem, 1.2vw, 1rem);
  line-height: clamp(1.4rem, 1.8vw, 1.7rem);
  font-weight: normal;
}

p {
  margin-bottom: .9rem;
  font-weight: 400;

  &:last-child {
    margin-bottom: 0;
  }

  strong {
    font-weight: 500;
  }
}

.tag,
.medium,
.exerpt {
  font-size: clamp(.9rem, 2vw, 0.83333rem);
  line-height: clamp(1rem, 4vw, 1.38889rem);
  font-weight: 500;
}

h6,
.medium {
  font-size: $medium;
  line-height: normal;
  font-weight: 500;
}

h5,
.subtitle,
.big {
  font-size: clamp(1rem, 2vw, 1rem);
  line-height: clamp(1.6rem, 3.4vw, 1.83333rem);
  line-height: normal;
  font-weight: 600;
}

h4,
.large:not(input):not(textarea) {
  font-size: clamp(1rem, 2vw, 1.66667rem);
  line-height: clamp(1.6rem, 3.8vw, 2.11111rem);
  font-weight: 700;
}

h3 {
  font-size: clamp(1.2rem, 2.2vw, 2rem);
  line-height: clamp(1.4rem, 2.4vw, 2.4rem);
  font-weight: 700;
}

h2 {
  font-size: clamp(1.7rem, 2.8vw, 2.77778rem);
  line-height: clamp(1.8rem, 2.6vw, 3rem);
  font-weight: 700;
}

h1 {
  font-size: clamp(2rem, 7vw, 3.33333rem);
  line-height: clamp(2.8rem, 6vw, 3.47333rem);
  font-weight: 700;
}

.btn-primary {
  padding: 1rem 2.5rem;
  justify-content: center;
  align-items: center;
  gap: 0.55556rem;
  color: $color-white;
  background-color: $color-red;
  width: fit-content;
  border-radius: $rounded-xl;
  font-size: clamp(0.9rem, 1.7vw, 1rem);
  font-style: normal;
  font-weight: 600;
  line-height: 1rem;
  border: solid 1px $color-red;
  transition: all 0.3s ease;
  position: relative;
  z-index: 0;
  overflow: hidden;
  transition: color 0.3s ease;

  @media(max-width:768px) {
    padding: .5rem 1.3rem;
  }

  &:before {
    content: "";
    height: 0;
    aspect-ratio: 2;
    transform: translateX(-50%);
    position: absolute;
    top: -200%;
    left: 50%;
    background: white;
    border-radius: 50%;
    transition: height 0.3s;
    z-index: -1;
  }

  &:hover {
    color: $color-red;

    &:before {
      height: 400%;
    }
  }

  &.black {
    background-color: $color-primary;
    border-radius: $rounded-xs;
    padding: .56rem 2.44rem;
    font-size: 1rem;
    font-weight: 700;
    line-height: 1.11111rem;

    &:hover {
      color: $color-primary;
    }
  }

  &.white {
    background-color: $color-white;
    color: $color-primary;
    padding: 0.72222rem 1.44444rem 0.72222rem 1.44444rem;
    font-size: clamp(0.9rem, 1.7vw, 1.38889rem);
    font-weight: 600;
    line-height: clamp(1rem, 1.7vw, 1.44rem);

    &:hover {
      color: $color-white;
      border: solid 1px $color-white;

      &:before {
        background-color: $color-red;
        height: 400%;
      }
    }
  }

  &.load-more {
    display: flex;
    align-items: center;
    padding: .3rem 2.5rem;
    line-height: clamp(2rem, 3vw, 2.5rem);

    .loader {
      width: 0;

      path {
        fill: $color-red;
        transition: fill 0.3s ease;
      }
    }

    &.loading {
      .loader {
        width: 40px;
      }
    }

    &:hover {
      .loader {

        path {
          fill: $color-white;
          transition: fill 0.3s ease;
        }
      }
    }
  }

  &.reverse {
    background-color: $color-white;
    color: $color-red;

    &:hover {
      color: $color-white;

      &:before {
        background-color: $color-red;
        height: 400%;
      }
    }
  }

  &.icon {
    display: flex;

    &:hover {
      color: $color-red;

      i {
        color: $color-red;
        transition: all 0.3s ease;
      }
    }
  }
}

.red {
  color: $color-red;
}

.tag {
  color: $color-gray-dark;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: fit-content;

  &.author {
    padding-left: 1.44rem;

    &:before {
      top: 0;
      content: "\f007";
      font-family: 'Font Awesome 6 Pro';
      position: absolute;
      display: block;
      color: $color-gray-light;
      left: 0;
      opacity: 1;
      font-weight: 600;
      font-size: 0.9rem;
    }
  }

  &.book,
  &.localisation,
  &.date {
    padding-left: 1.44rem;

    &:before {
      top: 0;
      font-family: 'Font Awesome 6 Pro';
      position: absolute;
      display: block;
      color: $color-gray-light;
      left: 0;
      opacity: 1;
      font-weight: 600;
      font-size: 0.9rem;
    }
  }

  &.date {
    &:before {
      content: "\f073";
    }
  }

  &.book {
    &:before {
      content: "\f02d";
    }
  }

  &.localisation {
    &:before {
      content: "\f3c5";
    }
  }
}

.cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.contain {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
}

a {
  &.animated-link {
    color: $color-red;
    position: relative;
    width: fit-content;

    &:before {
      position: absolute;
      bottom: -2px;
      left: 0;
      content: "";
      width: 0%;
      background-color: $color-red;
      height: 1px;
      transition: width 0.4s ease-in-out;
    }

    &:hover {
      &:before {
        width: 100%;
        transition: width 0.4s ease-in-out;
      }
    }

    &.reverse {
      &:before {
        width: 100%;
      }

      &:hover {
        &:before {
          width: 0%;
        }
      }
    }

    &.white {
      color: $color-white;

      &:before {
        background-color: $color-white;
      }
    }

    &.black {
      color: $color-primary;

      &:before {
        background-color: $color-primary;
      }
    }
  }
}