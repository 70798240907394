@import "../../assets/styles/scss/variables";

.bloc-letter {
  display: flex;
  flex-direction: column;
  gap: 1.33rem;
  border-bottom: solid 1px $color-gray;
  padding: 1.33rem 0;

  .letter-infos {
    display: flex;
    width: fit-content;
    gap: 3.94rem;
  }

  .letter-content {
    display: flex;
    justify-content: space-between;
    gap: 1.11rem;
    align-items: flex-end;

    .content {
      display: flex;
      flex-direction: column;
      padding: 0 0 1.17rem;
      gap: 1.11rem;

      .subtitle {
        font-size: clamp(.9rem, 3vw, 1rem);
      }
    }

    h4 {
      transition: all ease 0.2s;
    }

    &:hover {
      h4 {
        color: $color-red;
        transition: all ease 0.2s;
      }
    }

    @media(max-width: 768px) {
      flex-direction: column;
      gap: 1.33rem;
    }
  }
}
