@import "../../assets/styles/scss/variables";

.accordion {
  position: relative;
  box-shadow: $shadow;
  border-radius: $rounded-xs;
  transition: all ease 0.4s;

  &:hover {
    box-shadow: $shadow-s;
    transition: all ease 0.4s;
  }

  .accordion-title {
    padding: .94rem 2.11rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: $small;
    cursor: pointer;

    .icon-close {
      i:before {
        font-size: 1.7rem;
        color: $color-red;
      }
    }

    .icon-open {
      display: none;

      i:before {
        font-size: 1.7rem;
        color: $color-red;
      }
    }

    &.is-open {
      .icon-close {
        display: none;
      }

      .icon-open {
        display: flex;
      }
    }
  }

  .accordion-content {
    display: none;
    padding: .94rem 2.11rem;
    padding-bottom: $large;
  }

  @media(max-width: 768px) {

    .accordion-title,
    .accordion-content {
      padding: .74rem 1.11rem;
    }

    .accordion-content {
      padding-bottom: 2rem;
    }
  }
}