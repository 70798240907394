//
// Header Styles
// --------------------------------------------------

//== Header height
:root {
	--header-height: 5.89rem;
}

$header-height: var(--header-height);

#header {
	width: 100%;
	background-color: $color-red;
	display: flex;
	align-items: center;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 20000;
	transition: all 0.4s linear;
	border-radius: 0 0 $rounded-xs $rounded-xs;

	.container {
		display: flex;
		align-items: center;
		height: 100%;
	}

	#logo {
		float: left;
		max-width: 11.11111rem;
	}

	&.sticky {
		#logo {
			max-width: 10.333rem;
		}

		#menu {
			margin-top: -2.5rem;

			@media (max-width:1240px) {
				margin-top: 0;
			}

			#menu-main-container {
				ul {
					li {
						a {
							padding: 3.39rem 0 1rem;

							&:before {
								bottom: 18px;
							}
						}

						&.menu-item-has-children:after {
							top: 69%;
						}

						&.menu-item-has-children:hover {
							.sub-menu {
								top: calc(100% + .6rem);
							}
						}

						&.link-ext:after {
							top: 58.5%;
						}
					}
				}
			}
		}

		#header-extras {
			margin-top: -2.5rem;
			gap: 1.1rem;
		}

		@media (max-width:1440px) {
			#logo {
				max-width: 6.95rem;
			}
		}

		@media (max-width:767px) {
			#logo {
				max-width: 5.95rem;
			}
		}
	}

	@media (max-width:1440px) {
		#logo {
			max-width: 9.95rem;
		}
	}

	@media (max-width:768px) {
		#logo {
			max-width: 6.95rem;
		}
	}

	#header-content {
		display: flex;
		align-items: center;
		height: 100%;
		width: 100%;
		justify-content: space-between;
	}

	#menu-mobile-trigger {
		font-size: 31px;
		display: none;

		.trigger-bar {
			display: block;
			width: 25px;
			height: 2px;
			transition: all 0.2s linear;
			position: relative;
			background-color: $color-white;

			&:not(:first-child) {
				margin-top: 6px;
			}
		}

		@media (max-width:1199px) {
			display: block;
		}
	}

	#menu {
		transition: all 0.4s linear;
		float: right;
		display: flex;
		align-items: flex-end;
		height: 100%;
		flex-direction: column;
		justify-content: center;

		@media (max-width:1199px) {
			gap: 0.5rem;
		}

		&.is-open {
			#menu-mobile-trigger {

				.trigger-bar:nth-child(1) {
					-webkit-transform: translateY(10px) rotate(-225deg);
					transform: translateY(10px) rotate(-225deg);
					top: -3px;
				}

				.trigger-bar:nth-child(2) {
					opacity: 0;
				}

				.trigger-bar:nth-child(3) {
					-webkit-transform: translateY(-10px) rotate(225deg);
					transform: translateY(-10px) rotate(225deg);
					margin-top: 7px;
				}
			}
		}

		#menu-main-container {
			display: flex;
			align-items: center;
			width: 100%;
			height: 100%;

			#close-mobile-menu {
				display: none;
			}

			ul {
				height: 100%;
				display: flex;
				align-items: center;
				margin: 0;
				padding: 0;
				list-style: none;
				text-align: right;
				float: right;

				li {
					height: 100%;
					display: flex;
					align-items: center;
					float: left;
					position: relative;
					padding-right: 3.89rem;
					transition: all 0.4s ease;

					@media(max-width: 1540px) {
						transition: all 0.4s ease;
						padding-right: 2.59rem;
					}

					a {
						padding: 3.39rem 0 1.45rem;
						font-size: clamp(0.9em, 4vw, 1.11111rem);
						display: block;
						font-weight: 700;
						line-height: 1.77778rem;
						color: $color-white;
						height: 100%;
						display: flex;
						align-items: center;
						position: relative;

						&:hover {
							color: $color-white;

							&:after {
								color: $color-white;
							}

							&:before {
								width: 100%;
								transition: width 0.3s ease;
							}
						}

						&:before {
							content: "";
							height: 1px;
							bottom: 25px;
							background-color: $color-white;
							position: absolute;
							width: 0;
							transition: width 0.3s ease;
						}
					}

					&.link-ext {
						padding-right: 5.22rem;
						transition: all 0.4s ease;

						@media(max-width: 1540px) {
							transition: all 0.4s ease;
							padding-right: 4.22rem;
						}

						a {
							&:before {
								width: 0;
								transition: width 0.3s ease;
							}

							&:hover {
								&:before {
									width: calc(100% + 1.6rem);
									transition: width 0.3s ease;
								}
							}
						}

						&:after {
							content: "\f08e";
							font-family: 'Font Awesome 6 Pro';
							position: absolute;
							display: block;
							top: 55.5%;
							right: 3.7rem;
							opacity: 1;
							font-weight: 600;
							font-size: 0.9rem;
							transition: all 0.4s ease;
							transform-origin: center;
							transition: transform 0.25s;
							color: $color-white;

							@media(max-width: 1540px) {
								right: 2.7rem;
							}
						}
					}

					&:last-child {
						margin-right: 0;
					}

					&.menu-item-has-children {
						a {
							color: $color-white;

							&:before {
								width: 0;
								transition: width 0.3s ease;
							}

							&:hover {
								&:before {
									width: calc(100% + 1.6rem);
									transition: width 0.3s ease;
								}
							}
						}
					}

					&.current-menu-item {
						>a {
							color: $color-white;

							&:before {
								width: 100% ;
								transition: width 0.3s ease;
							}

							&:hover {
								&:before {
									width: 100%;
									transition: width 0.3s ease;
								}
							}
						}
					}

					&.current-menu-ancestor,
					&.current-menu-parent,
					&.current-product-ancestor,
					&.current-product_cat-ancestor,
					&.current-page-ancestor {

						>a {
							color: $color-white;

							&:before {
								width: calc(100% + 1.7rem);
								transition: width 0.3s ease;
							}

							&:hover {
								&:before {
									width: calc(100% + 1.7rem);
									transition: width 0.3s ease;
								}
							}
						}

						.sub-menu {
							li {
								a {
									color: $color-primary;

									&:before {
										content: "";
										height: 0px;
									}
								}
							}
						}
					}

					.sub-menu {
						display: none;
						flex-direction: column;
						align-items: flex-start;
						background-color: $color-white;
						border-radius: $rounded-s;
						opacity: 0;
						top: 0;
						height: 100%;
						padding: 1.28rem;
						position: absolute;
						text-align: left;
						margin-left: -20px;
						transform: scale(0.95) translateY(0);
						transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
						z-index: 10;
						overflow: hidden;
						border-radius: $rounded-xs;
						box-shadow: $shadow-s;

						li {
							width: 100%;
							white-space: nowrap;
							margin: 0;
							float: none;
							padding: 0;
							border-bottom: 1px solid $color-pink;

							a {
								width: 100%;
								display: block;
								color: $color-primary;
								background-color: $color-white;
								font-weight: 400;
								padding: .6rem 4rem .6rem .6rem;
								font-size: clamp(1rem, 4vw, 1.16667rem);

								&:hover {
									color: $color-red;
									background-color: $color-white;

									&:before {
										content: "";
										height: 0px;
									}
								}

								&:before {
									content: "";
									height: 0px;
								}
							}

							&:first-child {
								margin-bottom: 0;

								a {
									padding-top: 0;
									padding-bottom: .6rem;
								}
							}

							&:last-child {
								border-bottom: 0px solid $color-primary;

								a {
									padding-top: .6rem;
									padding-bottom: 0;
								}
							}

							&.current-menu-item,
							&.current-menu-ancestor,
							&.current-menu-parent,
							&.current-product-ancestor,
							&.current-product_cat-ancestor,
							&.current-page-ancestor {
								a {
									color: $color-red;
								}
							}
						}

						li.anchor-link {
							a {
								color: $color-primary;
							}

							&.current-menu-item,
							&.current-menu-ancestor,
							&.current-menu-parent,
							&.current-product-ancestor,
							&.current-product_cat-ancestor,
							&.current-page-ancestor {
								a {
									color: $color-primary;
								}
							}

							a:hover {
								color: $color-primary;
							}
						}

						li.menu-file {
							&:after {
								content: "\f08e";
								font-family: 'Font Awesome 6 Regular';
								position: absolute;
								right: 10px;
								opacity: 0;
								transition: all 0.4s ease;
							}

							&:hover:after {
								opacity: 1;
								transition: all 0.4s ease;
							}
						}
					}

					&.menu-item-has-children {
						padding-right: 5.22rem;
						transition: all 0.4s ease;

						@media(max-width: 1540px) {
							transition: all 0.4s ease;
							padding-right: 4.22rem;
						}

						&:after {
							font-family: "Font Awesome 6 Pro";
							content: '\f078';
							display: block;
							position: absolute;
							top: 65%;
							font-weight: 600;
							font-size: 0.9rem;
							right: 3.7rem;
							transform: translateY(-50%);
							transform-origin: center;
							transition: transform 0.25s;
							color: $color-white;
							transition: all 0.4s ease;

							@media(max-width: 1540px) {
								transition: all 0.4s ease;
								right: 2.7rem;
							}
						}

						&:hover {
							&:after {
								transform: translateY(-50%) rotate(180deg);
							}

							.sub-menu {
								display: flex;
								height: auto;
								opacity: 1;
								top: 100%;
								transform: scale(1);
							}
						}
					}
				}
			}
		}
	}

	#extra-menu {
		display: flex;
		flex-direction: row;
		height: 50%;
		align-items: flex-start;
		position: absolute;
		top: 5px;

		ul {
			display: flex;
			gap: 1.64rem;
			flex-direction: row;
			list-style: none;
			text-align: right;
			float: right;
			margin-top: 0px;
			margin-bottom: 0px;
			padding-inline-start: 0px;

			li {
				position: relative;
				color: $color-white;
				transition: all ease 0.3s;
				border-bottom: solid transparent 1px;

				&:after {
					content: '';
					position: absolute;
					width: 100%;
					transform: scaleX(0);
					border-radius: 5px;
					height: 0.05em;
					bottom: 0;
					left: 0;
					transform-origin: bottom right;
					background: $color-primary;
					transition: transform .25s ease-out;
				}

				&:before {
					padding: 0 0.6rem 0 0;
					font-family: "Font Awesome 6 Pro";
					content: "\f054";
				}

				&:hover {
					color: $color-primary;
					transition: all ease 0.3s;

					&:after {
						transform: scaleX(1);
						transform-origin: bottom left;
						transition-duration: .3s;
					}

					a {
						color: $color-primary;
						transition: all ease 0.3s;
					}
				}
			}

			@media (max-width:1280px) {
				a {
					font-size: 1.1rem;
				}
			}
		}
	}

	#header-extras {
		display: flex;
		flex-direction: column;
		gap: .56rem;

		.menu-container {
			display: flex;
			flex-direction: row;
			gap: 1.94rem;
			justify-content: flex-end;
			list-style-type: none;
			padding: 0;
			margin: 0;

			li {
				a {
					color: $color-white;
					font-size: clamp(.7rem, 3vw, .9rem);
					font-weight: 400;
					line-height: 1.38889rem;
				}
			}

			.btn-primary {
				width: 100%;
				font-size: clamp(.7rem, 3vw, 1rem);
			}
		}

		@media(max-width: 1199px) {
			display: none;
		}
	}

	#mobile-extras {
		display: none;
		flex-direction: column;
		gap: .56rem;

		.menu-container {
			display: flex;
			flex-direction: row;
			gap: 1.94rem;
			list-style-type: none;
			padding: 0;
			margin: 0;

			li {
				a {
					color: $color-white;
					font-size: clamp(.7rem, 3vw, 1rem);
					font-weight: 400;
					line-height: 1.38889rem;
				}
			}

			.btn-primary {
				width: 100%;
				font-size: clamp(.7rem, 3vw, 1rem);
			}
		}

		@media(max-width: 1199px) {
			display: flex;
		}
	}
}

.mm-ocd--right {

	.mm-ocd__backdrop {
		display: none;
	}

	.mm-ocd__content {
		padding-top: var(--header-height);

		#mobile-extras {
			opacity: 0;
		}

		#menu-main-container {
			#menu-principal {
				height: auto;
				position: relative;
				min-height: 40%;
			}

			#button-start {
				z-index: 10;
				height: 27px;
				transform: translateX(calc(50vw / 2)) translateY(2rem);
			}

			#mobile-extras {
				display: flex;
				opacity: 1;
				position: absolute;
				width: 100%;
				bottom: 0;
				z-index: 100;
				padding: 2.6rem;
				justify-content: center;
				flex-direction: column;
				align-items: center;

				#languages-switcher {
					display: flex;
					align-items: center;
					width: 100%;
					min-height: 3rem;
					position: relative;

					.languages-list {
						display: flex;
						position: relative;
						height: auto;
						width: 100%;
						gap: 1rem;
						padding-bottom: 2rem;
						justify-content: center;

						button {
							background-color: $color-pink;
							color: $color-primary;

							&.active {
								background-color: $color-primary;
								color: $color-white;
							}
						}
					}
				}

				.btn-primary {
					span {
						padding: 0;
					}
				}
			}

			#extra-menu {
				height: auto;

				#menu-extra-menu {
					position: relative;
					left: 0;
					bottom: 0;
					top: auto;

					&:before {
						border-top: 0;
					}

					li {
						&:before {
							visibility: hidden;
						}

						a {
							color: $color-primary;
						}
					}
				}
			}
		}
	}

	.mm-spn.mm-spn--light {
		background: $color-white;

		&::after,
		&::before {
			opacity: 1;
			color: $color-primary;
		}

		li {
			&:last-of-type {
				&::after {
					border-top: 0px solid;
				}
			}

			a {
				color: $color-primary;
				font-size: 1.15rem;
				padding: 1.2rem 1.6rem;
				text-transform: capitalize;
			}

			&.current_page_item a,
			&.current-menu-parent a {
				color: $color-red;
				font-weight: 700;
			}
		}

		.menu-item-has-children {
			&::before {
				display: flex;
				justify-content: flex-end;
				align-items: center;
				font-family: "Font Awesome 6 Pro";
				content: '\f054';
				height: 100%;
				width: 100%;
				top: 0;
				right: 30px;
				z-index: 10;
				position: absolute;
				transform: rotate(0) translate(0);
				border-top: none;
				border-right: none;
				opacity: 1;
				color: $color-primary;
			}

			a {
				&:after {
					border-right: 0px solid;
					opacity: 1;
				}
			}

			.sub-menu {
				z-index: 20;

				a {
					color: $color-primary;
					font-weight: 500;
				}

				.current-menu-item {
					a {
						color: $color-red;
						font-weight: 700;
					}
				}
			}
		}
	}

	@media (max-width:767px) {
		#menu-principal {
			li {
				&:last-of-type {
					&::after {
						content: "";
						display: block;
						margin-left: var(--mm-spn-item-indent);
						border-top: 1px solid;
						opacity: .15;
					}
				}
			}
		}
	}
}

.mm-ocd--open {

	#menu-principal:before {
		border-top: 0;
	}

	.mm-ocd__content {
		width: 100vw;
		left: 0;
		max-width: 100vw;
	}
}