@import "../../assets/styles/scss/variables";

.bloc-service {
  display: flex;
  flex-direction: column;

  &.mid {
    grid-column: span 1;
  }

  &.full {
    grid-column: span 2;
  }

  .img-container {
    display: flex;
    width: 100%;
    max-height: 28rem;
    height: 28rem;
    min-height: 28rem;
    overflow: hidden;
    border-radius: $rounded-m;
    margin-bottom: 1.33rem;

    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      max-height: 28rem;
      height: 28rem;
      min-height: 28rem;
      scale: 1.02;
      transition: all ease 0.7s;
    }
  }

  &:hover {
    .img-container {
      img {
        scale: 1;
        transition: all ease 0.7s;
      }
    }
  }

  .post-title {
    font-size: clamp(1.1rem,1.7vw,1.8rem);
    line-height: clamp(1.5rem, 1.8vw, 2rem);
    padding-bottom: .67rem;
  }

  .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .service-content {
      display: flex;
      flex-direction: column;
      gap: 1.72rem;
      padding-bottom: 1.67rem;
    }
  }

  @media(max-width: 991px) {
    .img-container {
      max-height: 18rem;
      height: 18rem;
      min-height: 18rem;

      img {
        max-height: 18rem;
        height: 18rem;
        min-height: 18rem;
      }
    }

    &.mid {
      grid-column: span 2;
    }
  
    &.full {
      grid-column: span 2;
    }
  }

  @media(max-width: 768px) {
    &.mid {
      grid-column: span 4;
    }
  
    &.full {
      grid-column: span 4;
    }
  }
}