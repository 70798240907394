@import "../../assets/styles/scss/variables";


.header-small {
  padding-top: calc(3.33rem + $header-height);
  margin: 0 auto;

  .red {
    color: $color-red;
  }
    
  @media(max-width: 768px) {
    padding-top: calc(1.33rem + $header-height);
  }
}