@import "../../assets/styles/scss/variables";

.bloc-price {
  display: flex;
  flex-direction: column;
  gap: 1.72rem;
  padding: 1.75rem 1.45rem;
  border-radius: $rounded-m;
  box-shadow: $shadow;

  .img-container {
    display: flex;
    width: 100%;
    max-height: 18rem;
    min-height: 18rem;
    height: 18rem;
    overflow: hidden;
    border-radius: $rounded-m;

    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      max-height: 18rem;
      min-height: 18rem;
      height: 18rem;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    
    .price-content {
      display: flex;
      flex-direction: column;
      gap: 1.72rem;
    }
  }

  .tag-container {
    display: flex;
    gap: 1rem;
    align-items: flex-start;

    .tag {
      color: $color-primary;
      border-radius: $rounded-xs;
      border: solid 1px $color-primary;
      padding: 0.33rem 1rem;
      font-size: 1rem;
      font-weight: 600;
      line-height: 1.38889rem;
    }
  }

  .blog-content {
    display: flex;
    flex-direction: column;
    gap: 0.56rem;
  }

  .blog-infos {
    padding-top: 1rem;
    display: flex;
    justify-content: flex-start;
    gap: 2.61rem;
  }
}