@import "../../assets/styles/scss/variables";

.bloc-cta {
  display: flex;
  justify-content: space-between;
  padding: 5rem 5.56rem;
  margin-bottom: 7.89rem;
  border-radius: $rounded-l;
  background-color: $color-red;
  align-items: center;

  .content {
    display: flex;
    flex-direction: column;
    gap: 1.17rem;
    width: 85%;

    .title, .content {
      color: $color-white;
    }
  }

  @media(max-width: 991px) {
    flex-direction: column;
    align-items: flex-end;
    gap: 1.61rem;
    padding: 3rem 3.56rem;

    .content {
      width: 100%;
  
      .title, .content {
        color: $color-white;
      }
    }
  }

  @media(max-width: 768px) {
    flex-direction: column;
    align-items: flex-end;
    gap: 1.61rem;
    padding: 2rem 2rem;
    border-radius: $rounded-xs;

    .content {
      width: 100%;
  
      .title, .content {
        color: $color-white;
      }
    }
  }
}
