@import "../../assets/styles/scss/variables";

.bloc-travel {
  display: flex;
  flex-direction: column;
  gap: 1.72rem;
  box-shadow: $shadow;
  border-radius: $rounded-m;
  padding: 1.94rem 1.61rem;
  justify-content: space-between;

  .img-container {
    display: flex;
    width: 100%;
    max-height: 24rem;
    height: 24rem;
    min-height: 24rem;
    overflow: hidden;
    border-radius: $rounded-m;

    a {
      width: 100%;

      &:hover {
        img {
          scale: 1;
          transition: all ease 0.4s;
        }
      }
    }

    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      max-height: 24rem;
      height: 24rem;
      min-height: 24rem;
      scale: 1.02;
      transition: all ease 0.4s;
    }
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding-top: 1.5rem;
    padding-bottom: 1.44rem;
    
    .blog-content {
      display: flex;
      flex-direction: column;
      gap: 1.72rem;
      padding-bottom: 1.44rem;
    }
  }

  .blog-infos {
    padding-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: .67rem;
  }

  @media(max-width: 991px) {
    .img-container {
      max-height: 16rem;
      height: 16rem;
      min-height: 16rem;

      img {
        max-height: 16rem;
        height: 16rem;
        min-height: 16rem;
      }
    }
  }

  @media(max-width: 1240px) {
    gap: 0;

    .img-container {
      max-height: 14rem;
      height: 14rem;
      min-height: 14rem;

      img {
        max-height: 14rem;
        height: 14rem;
        min-height: 14rem;
      }
    }

    .content {
      padding-bottom: 2.5rem;
      
      .blog-content {
        gap: .72rem;
        padding-bottom: 1.84rem;
      }
    }
  }
}