@import "../../assets/styles/scss/variables";

.bloc-magazine {
  display: flex;
  flex-direction: column;
  gap: .72rem;

  .img-container {
    display: flex;
    width: 100%;
    max-height: 43rem;
    overflow: hidden;
    border-radius: $rounded-m;

    img {
      object-fit: cover;
      object-position: center;
      width: 100%;
      scale: 1.02;
      transition: all ease 0.4s;
      border-radius: $rounded-m;
    }
  }

  &:hover {
    img {
      scale: 1;
      transition: all ease 0.4s;
    }
  }

  .magazine-infos {
    display: flex;
    gap: 1rem;
    flex-direction: row;
    align-items: flex-start;
  }
}