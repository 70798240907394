@import "../../assets/styles/scss/variables";


.header-single {
  padding-top: calc(3.33rem + $header-height);

  @media(max-width: 768px) {
    padding-top: calc(1.33rem + $header-height);
  }

  img {
    height: 35.55rem;
    max-height: 35.55rem;
    min-height: 35.55rem;
    width: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: $rounded-xs;

    @media (max-width: 991px) {
      height: 24rem;
      min-height: 24rem;
      max-height: 24rem;
    }

    @media (max-width: 768px) {
      height: 18rem;
      min-height: 18rem;
      max-height: 18rem;
    }
  }

  #breadcrumbs {
    background-color: $color-white;

    .breadcrumb_last {
      color: $color-red;
    }

    span {
      display: flex;
      flex-direction: row;
      align-items: center;

      &>* {
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;
      }
    }

    .separator {
      width: 1rem;
      padding: 0rem 0.6rem;

      &:before {
        margin-bottom: -3px;
        content: "\f054";
        font-family: 'Font Awesome 6 Pro';
        display: block;
        opacity: 1;
        font-weight: 300;
        font-size: clamp(0.5rem, 2vw, 0.75rem);

        @media (max-width: 991px) {
          bottom: -0.08rem;
        }

        @media (max-width: 768px) {
          bottom: -0, 02rem;
        }
      }
    }
  }


  .tags {
    padding: 2rem 0;
    display: flex;
    gap: 2.111rem;

    .category-tag {
      width: fit-content;
    }

    @media (max-width: 768px) {
      flex-wrap: wrap;
      gap: .7rem;

      .category-tag {
        width: 100%;
      }
    }
  }

  .tag {
    display: flex;
    align-items: center;

    &.category {
      color: $color-primary;
      border-radius: $rounded-xs;
      border: solid 1px $color-primary;
      padding: 0.33rem 1rem;
      font-weight: 600;
      transition: all ease 0.3s;

      &:hover {
        background-color: $color-primary;
        color: $color-white;
        transition: all ease 0.3s;
      }
    }

    &:before {
      height: 100%;
      display: flex;
      font-size: .9rem;
      align-items: center;
    }
  }
}