//
// Footer Styles
// --------------------------------------------------

#footer {
	background-color: $color-red;
	color: $color-white;
	font-size: 14px;
	padding: 1.67rem 0 2.67rem;

	.footer-bloc-title {
		margin-top: 0;
	}

	ul {
		padding: 0;
		margin: 0;
		list-style: none;

		li {
			a {
				color: $color-white;

				&:hover {
					color: $color-white;
				}
			}
		}
	}

	.footer-bottom-block-copyright {
		display: flex;
		flex-direction: row;
		align-items: center;
		gap: 2rem;

		a {
			position: relative;
			display: flex;
			flex-direction: row;
			align-items: center;

			span {
				font-weight: 400;
			}

			&::after {
				position: absolute;
				content: "";
				right: -1rem;
				height: 70%;
				width: 1px;
				background-color: $color-white;
			}

			&:last-child {
				&::after {
					position: absolute;
					content: "";
					right: -1rem;
					height: 70%;
					width: 0px;
					background-color: $color-white;
				}
			}
		}
	}

	#footer-top {
		display: flex;
		flex-direction: column;
		gap: 1.67rem;

		.partners-list {
			display: flex;
			flex-wrap: wrap;
			flex-direction: row;
			background-color: $color-white;
			border-radius: $rounded-xs;
			padding: 1.11rem;
			gap: 2.06rem;

			.swiper-slide {
				display: flex;
				align-items: center;
				justify-content: center;
				height: 100%;
				max-height: 3.1rem;
			}

			.bloc-partner {
				height: 100%;
				max-height: 3.1rem;

				img {
					max-height: 3.1rem;
					object-fit: contain;
				}
			}
		}

		.navigation {
			display: flex;
			width: 60%;
			justify-content: space-between;
			gap: 2rem;

			ul {
				display: grid;
				grid-template-columns: repeat(2, 1fr);
				column-gap: 6rem;
				row-gap: 2rem;
				padding-top: 2rem;

				li {
					display: flex;
					align-items: center;
					float: left;
					position: relative;
					transition: all 0.4s ease;

					@media(max-width: 1540px) {
						transition: all 0.4s ease;
						padding-right: 2.59rem;
					}

					a {
						font-size: clamp(0.9em, 4vw, 1.11111rem);
						display: block;
						font-weight: 700;
						line-height: 1.77778rem;
						color: $color-white;
						display: flex;
						align-items: center;

						&:hover {
							color: $color-white;
						}
					}

					&.link-ext {
						padding-right: 2.22rem;
						transition: all 0.4s ease;

						&:after {
							content: "\f08e";
							font-family: 'Font Awesome 6 Pro';
							position: absolute;
							display: block;
							right: .7rem;
							opacity: 1;
							font-weight: 600;
							font-size: 0.9rem;
							transition: all 0.4s ease;
							transform-origin: center;
							transition: transform 0.25s;
							color: $color-white;
						}
					}
				}
			}
		}
	}

	#footer-bottom {
		margin-top: 40px;
		font-size: 14px;
		display: flex;
		align-items: flex-start;
		justify-content: space-between;

		a {
			color: $color-white;
			font-weight: 700;
		}
	}

	@media(max-width:1440px) {
		#footer-top {
			.navigation {
				width: 100%;

				ul {
					column-gap: 3rem;
					row-gap: 1rem;
					padding-top: 1rem;

					li {
						width: fit-content;
					}
				}
			}
		}
	}

	@media(max-width: 1080px) {
		.footer-bottom-block-copyright {
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			gap: .36rem;

			a {

				&:first-child,
				&:nth-child(2) {
					&::after {
						width: 0;
					}
				}
			}
		}
	}

	@media(max-width:768px) {
		#footer-top {
			.navigation {
				padding-bottom: 3rem;

				ul {
					column-gap: 2rem;
					row-gap: .5rem;
					padding-top: 1rem;
				}
			}
		}
	}

	@media(max-width:640px) {
		#footer-top {
			.navigation {
				ul {
					column-gap: 1.3rem;
					row-gap: .2rem;
				}
			}


			.partners-list {
				.swiper-slide {
					max-height: 2.1rem;
				}

				.bloc-partner {
					max-height: 2.1rem;

					img {
						max-height: 2.1rem;
					}
				}
			}
		}

		#footer-bottom {
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			gap: 2rem;
		}
	}

	@media(max-width:540px) {
		#footer-top {
			.navigation {
				padding-top: 1rem;

				ul {
					column-gap: .8rem;
					row-gap: .2rem;
				}
			}
		}
	}

	@media(max-width:480px) {
		#footer-top {
			.navigation {
				flex-direction: column;
				padding-bottom: 1.7rem;

				ul {
					padding-top: 0rem;
				}
			}
		}
	}
}

#newsletter {
	box-shadow: $shadow;
	padding-top: 2.15rem;
	padding-bottom: 2.15rem;

	.container {
		display: flex;
		justify-content: center;
	}

	.content {
		width: 80%;
		display: grid;
		grid-template-columns: calc(60% - 1rem) calc(40% - 1rem);
		gap: 2rem;

		.left {
			display: flex;
			flex-direction: column;
			gap: .7rem;

			h3 {
				color: $color-red;
			}
		}

		.right {
			height: 100%;

			form {
				.gform_fields {
					display: flex;
					flex-direction: column;
					row-gap: 15px;

					.gfield {
						.gfield_checkbox {
							.gchoice {
								input {
									display: none;
								}

								label {
									font-size: 0.8rem;
									padding-right: 40px;
								}
							}
						}
					}
				}
			}
		}

		@media(max-width: 991px) {
			width: 100%;
			display: flex;
			flex-direction: column;

		}
	}
}

.powered {
	position: relative;
	color: inherit;

	&:hover {
		color: inherit;
		text-decoration: none;

		#logo-alt-footer-trois {
			fill: #FFD94A;
			transform: scale(1.2);
		}

		#logo-alt-footer-deux {
			fill: #FFD94A;
			transform: scale(1.2);
		}

		#logo-alt-footer-un {
			fill: #FFD94A;
			transform: scale(1.2);
		}

		&:after {
			width: 100px;
		}
	}

	svg {
		overflow: visible;
		width: 50px;
		height: 24px;
		margin: 0 0 0 5px;
		position: relative;
		top: -2px;
		vertical-align: middle;
		width: 34px;

		#logo-alt-footer-trois {
			fill: white;
			transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.1s;
			transform: scale(1);
			transform-origin: center;
		}

		#logo-alt-footer-deux {
			fill: white;
			transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.25s;
			transform: scale(1);
			transform-origin: center;
		}

		#logo-alt-footer-un {
			fill: white;
			transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1) 0.4s;
			transform: scale(1);
			transform-origin: center;
		}
	}

	&:after {
		display: block;
		content: '';
		position: absolute;
		bottom: -2px;
		left: 83px;
		width: 0px;
		height: 2px;
		background-color: #FFD94A;
		transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
	}
}