@import "../../assets/styles/scss/variables";


.header-homepage {
  padding-top: calc(3.33rem + $header-height);
  padding-bottom: 5rem;

  @media(max-width: 768px) {
    padding-top: calc(1.33rem + $header-height);
  }

  h1 {
    display: none;
  }

  .grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 2.78rem;

    .left {
      border-radius: $rounded-xs;
      height: 100%;
      overflow: hidden;

      img {
        object-fit: contain;
        object-position: top;
        border-radius: $rounded-xs;
      }
    }

    .right {

      h2 {
        font-size: clamp(1.3rem, 2.8vw, 2.77778rem);
        line-height: clamp(1.5rem, 2vw, 3rem);
      }

      .mag-info {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        gap: 1.34rem;
        padding: 1.2rem 0 .72rem;

        .tag {
          font-size: clamp(0.7rem, 1.7vw, 1.00167rem);
          font-weight: 700;
          line-height: 1rem;
          margin: 0;

          &:before {
            line-height: 1rem;
          }
        }
      }

      .mag-intro {
        font-size: clamp(.9rem, 1.7vw, 1rem);
        font-style: normal;
        font-weight: 600;
        line-height: clamp(1.4rem, 2vw, 1.61111rem);
        padding-top: 1.2rem;
      }
    }
  }

  .content {
    padding: 3rem 0;
    display: flex;
    flex-direction: column;
    gap: 1.5rem;

    .btn-primary {
      margin-top: 1rem;
    }
  }

  @media(max-width: 1080px) {
    .grid {
      .right {
        .mag-info {
          flex-direction: column;
          gap: .64rem;
          padding: 2.2rem 0 .72rem;

          .tag {
            font-size: clamp(1rem, 1.7vw, 1.00167rem);
          }
        }
      }
    }

    .mag-intro {
      font-weight: 600;
      padding-top: 1.2rem;
    }
  }

  @media(max-width: 804px) {
    .grid {
      gap: 1.111rem
    }

    .content {
      padding: 3rem 0 0;
      display: flex;
      flex-direction: column;
      gap: 1rem;
    }
  }
}