@import "../../assets/styles/scss/variables";


.header-standard {
  padding-top: calc(3.33rem + $header-height);

  .red {
    color: $color-red;
  }
    
  @media(max-width: 768px) {
    padding-top: calc(1.33rem + $header-height);
  }
}